import React from "react"
import { Styled } from "theme-ui"
import { graphql } from "gatsby"

import Layout, { Container } from "components/layout"
import { Numbers } from "components/sections"
import { TileLink } from "components/tiles"

export const query = graphql`
  query NumbersPageQuery {
    climateImage: file(relativePath: { eq: "hero/climate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const NumbersPage = ({ data }) => (
  <Layout pageTitle="By the Numbers">
    <Numbers />
    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink to="/climate" image={data.climateImage.childImageSharp.fluid}>
        Climate &amp; Energy
      </TileLink>
    </Container>
  </Layout>
)

export default NumbersPage
